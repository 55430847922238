<template>
    <el-main>
        <el-tabs v-model="step" @tab-click="handleClick">
            <el-tab-pane label="关系图" name="1">
                <el-tabs v-model="comefrom" type="card" @tab-click="getRelationList">
                    <el-tab-pane :name="item.comefrom" v-for="(item, index) in relationComeFrom" :key="index">
                        <span slot="label">{{ item.title }}（{{ item.count }}）</span>
                    </el-tab-pane>
                </el-tabs>
                <div v-show="list.length" style="overflow: auto">
                    <div id="chartDom" :style="{ width: width, height: height }"></div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="关系查询" name="2">
                <el-form>
                    <el-form-item label="选择分销员：">
                        <el-select @change="getRelationInfo" v-model="distribution_id" clearable filterable>
                            <el-option v-for="item in distributionList" :key="item.id" :label="item.nickname"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <template v-if="distribution_id">
                    <div class="info">
                        <div class="distribution-info">
                            <span>分销员信息：</span>
                            <el-image :src="relationInfo.avatar"></el-image>
                            <span>{{ relationInfo.nickname }}</span>
                        </div>
                        <div class="distribution-phone">手机号码：{{ relationInfo.mobile }}</div>
                        <div class="comefrom">
                            <span>用户来源：</span>
                            <el-image :src="relationInfo.comefrom_icon"></el-image>
                        </div>
                        <div class="ctime">注册时间：{{ relationInfo.create_time }}</div>
                        <template v-if="relationInfo.parent_avatar">
                            <div class="invite-info">
                                <span>邀请方昵称：</span>
                                <el-image :src="relationInfo.parent_avatar"></el-image>
                                <span>{{ relationInfo.parent_nickname }}</span>
                            </div>
                            <div class="invite-phone">邀请方手机号：{{ relationInfo.parent_mobile }}</div>
                        </template>
                    </div>
                    <p style="font-size: 14px; margin: 20px 0">其下关系链：</p>
                    <el-table :data="relationList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                        <template slot="empty">
                            <No />
                        </template>
                        <el-table-column prop="nickname" label="其下分销员/用户昵称" align="center"></el-table-column>
                        <el-table-column prop="mobile" label="其下分销员/用户手机号" align="center"></el-table-column>
                        <el-table-column prop="relation" label="当前关系" align="center"></el-table-column>
                        <el-table-column prop="create_time" label="关系更新时间" align="center"></el-table-column>
                    </el-table>
                </template>
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import * as echarts from 'echarts';
import _ from "lodash"
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
// import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
export default {
    data () {
        return {
            step: '1',
            relationComeFrom: [],
            comefrom: 1,
            myChart: null,
            distributionList: [],
            distribution_id: '',
            relationInfo: {},
            relationList: [],
            relationLen: [1],
            list: [],
            width: 0,
            height: 0,
            a: 1,
        };
    },
    created () {
        let query = this.$route.query;
        this.step = query.step || '1';
        this.getCommform();
        this.getDistributionList();
        if (query.id) {
            this.distribution_id = Number(query.id);
            this.getRelationInfo();
        }
    },
    mounted () {
        this.getRelationList();
    },
    methods: {
        facilitateData (data) {
            let arr = data;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].children instanceof Array) {
                    this.a++;
                    arr = arr[i].children;
                    this.facilitateData(arr);
                }
            }
        },
        facilitateLen (data) {
            let arr = [];
            data.map(item => {
                item.name = `${item.name} (${item.level_name})`;
                let children = item.children || [];
                arr.push(children.length);
                if (children.length) this.facilitateLen(item.children);
            });
            this.relationLen.push(arr);
        },
        initData (data) {
            let that = this;
            return {
                series: [
                    {
                        type: 'tree',
                        id: 0,
                        name: 'tree1',
                        data: [
                            {
                                name: '平台',
                                children: data,
                            },
                        ],
                        top: '10%',
                        left: '8%',
                        bottom: '22%',
                        right: '20%',
                        symbolSize: 7,
                        edgeForkPosition: '63%',
                        initialTreeDepth: 3,
                        lineStyle: {
                            width: 2,
                            color: '#409EFF',
                        },
                        itemStyle: {
                            normal: {
                                color: '#409EFF',
                                borderColor: '#409EFF',
                            },
                            emphasis: {
                                borderColor: '#409EFF',
                                color: '#409EFF',
                            },
                        },
                        label: {
                            backgroundColor: '#fff',
                            position: 'left',
                            verticalAlign: 'middle',
                            align: 'right',
                        },
                        leaves: {
                            label: {
                                position: 'right',
                                verticalAlign: 'middle',
                                align: 'left',
                            },
                        },
                        symbolSize: 10,
                        emphasis: {
                            focus: 'descendant',
                        },
                        expandAndCollapse: true,
                        animationDuration: 550,
                        animationDurationUpdate: 750,
                    },
                ],
            };
        },
        getCommform () {
            this.$axios.post('/Beauty' + this.$api.distribution.relationComeFrom).then(res => {
                if (res.code == 0) {
                    this.relationComeFrom = res.result;
                }
            });
        },
        getRelationList () {
            this.$axios
                .post('/Beauty' + this.$api.distribution.relationPicture, {
                    comefrom: this.comefrom,
                })
                .then(res => {
                    if (res.code == 0) {
                        let chartDom = document.getElementById('chartDom');
                        this.list = res.result;
                        this.relationLen = [[res.result.length]];
                        this.facilitateLen(_.cloneDeep(res.result));
                        this.facilitateData(_.cloneDeep(res.result));
                        let num = [];
                        for (let i in this.relationLen) {
                            let sum = 0;
                            for (let y in this.relationLen[i]) {
                                sum = sum + this.relationLen[i][y];
                            }
                            num.push(sum);
                        }
                        if (this.a > 1) {
                            this.width = this.a * 340 + 'px';
                            this.height = Math.max(...num) * 60 + 'px';
                        } else {
                            this.width = '700px';
                            this.height = Math.max(...num) * 60 + 'px';
                        }
                        setTimeout(() => {
                            this.myChart = echarts.init(chartDom);
                            this.myChart.setOption(this.initData(res.result));
                        }, 100);
                    }
                });
        },
        //查询关系信息
        getRelationInfo () {
            if (this.distribution_id) {
                this.$axios
                    .post('/Beauty' + this.$api.distribution.relation, {
                        id: this.distribution_id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            let info = res.result;
                            if (info.comefrom == 1) info.comefrom_icon = wx;
                            if (info.comefrom == 2) info.comefrom_icon = ali;
                            if (info.comefrom == 4) info.comefrom_icon = douyin;
                            if (info.comefrom == 5) info.comefrom_icon = qq;
                            if (info.comefrom == 6) info.comefrom_icon = baidu;
                            info.create_time = getDateformat(info.create_time);
                            this.relationInfo = info;
                            return this.$axios.post('/Beauty' + this.$api.distribution.relationList, {
                                id: this.distribution_id,
                            });
                        }
                    })
                    .then(res => {
                        if (res.code == 0) {
                            let list = res.result;
                            list.map(item => {
                                item.create_time = getDateformat(item.create_time);
                            });
                            this.relationList = list;
                        }
                    });
            }
        },
        //获取分销员列表
        getDistributionList () {
            this.$axios
                .post('/Beauty' + this.$api.distribution.list, {
                    rows: 100000,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list;
                        this.distributionList = list;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    #chartDom {
        width: 100%;
        height: 700px;
    }

    .info {
        display: flex;
        align-items: center;
        font-size: 14px;

        &>div {
            margin-right: 40px;
        }

        .distribution-info,
        .invite-info {
            display: flex;
            align-items: center;

            .el-image {
                width: 50px;
                height: 50px;
                margin: 0 15px;
            }
        }

        .comefrom {
            display: flex;
            align-items: center;
        }
    }
}
</style>
